var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-8" }, [
          _c("div", { staticClass: "card-title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("integrationInstructions.nipo.intro.header")) +
                " "
            ),
          ]),
          _c("div", { staticClass: "card-text" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("integrationInstructions.nipo.intro.description1")
                    ) +
                    " "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("integrationInstructions.nipo.intro.description2")
                    ) +
                    " "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("integrationInstructions.nipo.intro.description3")
                    ) +
                    " "
                ),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "integrationInstructions.nipo.intro.questionType1"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "integrationInstructions.nipo.intro.questionType2"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "integrationInstructions.nipo.intro.questionType3"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-md-4 text-right" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-sm",
                attrs: { variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.clickEditCleaningSettings()
                  },
                },
              },
              [
                _c("i", { staticClass: "bx bx-brush mr-2" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "integrationInstructions.general.EditCleaningSettingButton"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "card-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("integrationInstructions.nipo.step1.header")
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "card-text mt-3" }, [
                _c("ul", [
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "integrationInstructions.nipo.step1.point1.title"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "mt-1" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-light",
                          attrs: { href: this.nipoScriptUrl },
                        },
                        [
                          _c("i", {
                            staticClass: "bx bx-download font-size-18 mr-2",
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "integrationInstructions.nipo.step1.point1.downloadButton"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticClass: "mt-3" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "integrationInstructions.nipo.step1.point2.title"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "integrationInstructions.nipo.step1.point2.description"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("li", { staticClass: "mt-3" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "integrationInstructions.nipo.step1.point3.title"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "integrationInstructions.nipo.step1.point3.description1"
                            )
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "integrationInstructions.nipo.step1.point3.description2"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "card-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("integrationInstructions.nipo.step2.header")
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "card-text mt-3" }, [
                _c("ul", [
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("integrationInstructions.nipo.step2.point1")
                          ) +
                          " "
                      ),
                    ]),
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "input-group bg-light rounded mt-2" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: this.enableRedemApiScript,
                              expression: "this.enableRedemApiScript",
                            },
                          ],
                          staticClass: "form-control bg-transparent border-0",
                          attrs: { type: "text", disabled: "" },
                          domProps: { value: this.enableRedemApiScript },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                this,
                                "enableRedemApiScript",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light",
                            attrs: { type: "button", id: "button-addon2" },
                            on: {
                              click: function ($event) {
                                return _vm.copyEnableRedemApiScript()
                              },
                            },
                          },
                          [
                            this.copyEnableRedemApiScriptSuccess
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "b-badge",
                                      {
                                        staticClass: "mr-2",
                                        staticStyle: { "font-size": "12px" },
                                        attrs: { variant: "success" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "integrationInstructions.general.copied"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("i", { staticClass: "bx bx-copy-alt" }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("li", { staticClass: "mt-4" }, [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "integrationInstructions.nipo.step2.point2.title"
                          )
                        )
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "integrationInstructions.nipo.step2.point2.description"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "input-group bg-light rounded mt-3" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: this.invisibleFontForOdin,
                              expression: "this.invisibleFontForOdin",
                            },
                          ],
                          staticClass: "form-control bg-transparent border-0",
                          attrs: { type: "text", disabled: "" },
                          domProps: { value: this.invisibleFontForOdin },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                this,
                                "invisibleFontForOdin",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light",
                            attrs: { type: "button", id: "button-addon2" },
                            on: {
                              click: function ($event) {
                                return _vm.copyInvisibleFontForOdin()
                              },
                            },
                          },
                          [
                            this.copyInvisibleFontForOdinSuccess
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "b-badge",
                                      {
                                        staticClass: "mr-2",
                                        staticStyle: { "font-size": "12px" },
                                        attrs: { variant: "success" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "integrationInstructions.general.copied"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("i", { staticClass: "bx bx-copy-alt" }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "card-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("integrationInstructions.nipo.step3.header")
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "card-text mt-3" }, [
                _c("ul", [
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("integrationInstructions.nipo.step3.point1")
                        ) + " "
                      ),
                    ]),
                  ]),
                  _c(
                    "li",
                    [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "integrationInstructions.nipo.step3.point2"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._l(
                        _vm.datapoints,
                        function (datapoint, datapointIndex) {
                          return _c(
                            "div",
                            { key: datapointIndex, staticClass: "mt-3" },
                            [
                              _c("label", [
                                _vm._v(
                                  " " + _vm._s(datapoint.questionIdentifier)
                                ),
                              ]),
                              _c("ScriptWidget", {
                                attrs: { datapointsParameters: datapoint },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      _c("div", { staticClass: "mt-3" }, [
                        _c("label", [_vm._v(" End Script ")]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "integrationInstructions.nipo.step3.endScript"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "input-group bg-light rounded" },
                          [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: this.endScript,
                                  expression: "this.endScript",
                                },
                              ],
                              staticClass:
                                "form-control bg-transparent border-0",
                              staticStyle: { height: "155px" },
                              attrs: { type: "text", disabled: "" },
                              domProps: { value: this.endScript },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    this,
                                    "endScript",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-light",
                                attrs: { type: "button", id: "button-addon2" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyEndScriptToClipboard()
                                  },
                                },
                              },
                              [
                                this.copyEndScriptSuccess
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "b-badge",
                                          {
                                            staticClass: "mr-2",
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                            attrs: { variant: "success" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "integrationInstructions.general.copied"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("i", { staticClass: "bx bx-copy-alt" }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "fixed-bottom static-buttons",
          class: [_vm.isExpanded ? "expanded" : "collapsed"],
        },
        [
          _c("div", { staticClass: "card-body col-md-12 row" }, [
            _c(
              "div",
              { staticClass: "col-md-12 text-right" },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "https://help.redem.io/", target: "_blank" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "integrationInstructions.general.bestPracticesButton"
                        )
                      ) + " "
                    ),
                    _c("i", { staticClass: "bx bx-link-external ml-1" }),
                  ]
                ),
                !_vm.returnToResult
                  ? _c(
                      "b-button",
                      {
                        staticClass: "ml-4 mr-2",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.clickFinishButton()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "integrationInstructions.general.finishButton"
                              )
                            ) +
                            " "
                        ),
                        _c("i", { staticClass: "bx bx-check-double" }),
                      ]
                    )
                  : _vm._e(),
                _vm.returnToResult
                  ? _c(
                      "b-button",
                      {
                        staticClass: "ml-4 mr-2",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.clickBackToResult()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "integrationInstructions.general.backButton"
                              )
                            ) +
                            " "
                        ),
                        _c("i", { staticClass: "bx bx-right-arrow-alt" }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.displayEditCleaningSettingsModal
        ? _c("EditCleaningSettingsModal", {
            attrs: { visible: _vm.displayEditCleaningSettingsModal },
            on: {
              close: function ($event) {
                _vm.displayEditCleaningSettingsModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-md-3 text-center" }, [
        _c(
          "pre",
          {
            staticClass: "font-size-14 pt-2 pb-2",
            staticStyle: { background: "#f5f5f5" },
          },
          [
            _vm._v('*TEMPLATE "NfieldChicago;'),
            _c("span", { staticStyle: { color: "#00a181" } }, [
              _vm._v("redem_api"),
            ]),
            _vm._v('" '),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }