var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-text mt-3" }, [
    _c(
      "div",
      { staticClass: "mb-5" },
      [
        _c("ul", [
          _c("li", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "integrationInstructions.forsta.stepCopyPaste.description1"
                  )
                ) +
                " "
            ),
          ]),
        ]),
        _c("figure", [
          _c("img", {
            staticClass: "mb-2 mt-2",
            attrs: {
              src: "https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step3_Image1.png",
              width: "30%",
            },
            on: {
              click: function ($event) {
                _vm.lightbox2Visible = true
              },
            },
          }),
          _c("figcaption", { staticStyle: { color: "#bababa" } }, [
            _vm._v(
              _vm._s(
                _vm.$t("integrationInstructions.forsta.general.clickToEnlarge")
              )
            ),
          ]),
        ]),
        _c("VueEasyLightbox", {
          attrs: {
            visible: _vm.lightbox2Visible,
            imgs: "https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step3_Image1.png",
          },
          on: {
            hide: function ($event) {
              _vm.lightbox2Visible = false
            },
          },
        }),
        _c("ul", [
          _c("li", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "integrationInstructions.forsta.stepCopyPaste.description2"
                  )
                ) +
                " "
            ),
          ]),
        ]),
        _c(
          "ol",
          { staticClass: "mt-3" },
          _vm._l(_vm.oesDatapointIDS, function (datapointID, datapointIndex) {
            return _c(
              "li",
              { key: datapointIndex, staticClass: "mb-3" },
              [
                _c("p", [
                  _c("b", [_vm._v(_vm._s(datapointID))]),
                  _c("br", { staticClass: "mb-2" }),
                ]),
                _c("CopyPasteScript", {
                  staticClass: "mb-2",
                  attrs: {
                    datapointID: datapointID,
                    isScriptNode: false,
                    scriptHeight: "height: 151px;",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
    _c("hr"),
    _c(
      "div",
      { staticClass: "mt-5" },
      [
        _c("ul", [
          _c("li", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "integrationInstructions.forsta.stepCopyPaste.description3"
                  )
                )
            ),
          ]),
          _c("li", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "integrationInstructions.forsta.stepCopyPaste.description4"
                  )
                )
            ),
          ]),
        ]),
        _c("figure", [
          _c("img", {
            staticClass: "mb-2 mt-2",
            attrs: {
              src: "https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step3_Image2.png",
              width: "30%",
            },
            on: {
              click: function ($event) {
                _vm.lightbox3Visible = true
              },
            },
          }),
          _c("figcaption", { staticStyle: { color: "#bababa" } }, [
            _vm._v(
              _vm._s(
                _vm.$t("integrationInstructions.forsta.general.clickToEnlarge")
              )
            ),
          ]),
        ]),
        _c("VueEasyLightbox", {
          attrs: {
            visible: _vm.lightbox3Visible,
            imgs: "https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step3_Image2.png",
          },
          on: {
            hide: function ($event) {
              _vm.lightbox3Visible = false
            },
          },
        }),
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.$t(
                "integrationInstructions.forsta.stepCopyPaste.description5"
              )
            )
          ),
        ]),
        _c(
          "ol",
          _vm._l(_vm.oesDatapointIDS, function (datapointID, datapointIndex) {
            return _c(
              "li",
              { key: datapointIndex, staticClass: "mb-3" },
              [
                _c("p", [
                  _c("b", [
                    _vm._v(_vm._s(datapointID) + "_CopyPasteDetection"),
                  ]),
                  _c("br", { staticClass: "mb-2" }),
                ]),
                _c("CopyPasteScript", {
                  staticClass: "mb-2",
                  attrs: {
                    datapointID: datapointID,
                    isScriptNode: true,
                    scriptHeight: "height: 249px;",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }