<script>
import EditCleaningSettingsModal from "@/redem/datafiles/components/modals/edit-cleaning-settings-modal.vue";
import { EventBus } from "@/shared/util/event-bus.js";
import { ALGORITHM_TYPES } from "../../../../../shared/util/const.js";
import CopyPasteInstructions from "./forsta-copy-paste-instructions.vue";
import QuestionDurationScriptWidget from "./forsta-individual-question-duration-script-widget.vue";
import ScriptWidget from "./forsta-script-widget.vue";
// External Plugins
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  props: {
    datapoints: Array,
    datafileId: String,
    datafileSecretKey: String,
    trackTotalSurveyDuration: Boolean,
    returnToResult: Boolean
  },
  components: {
    ScriptWidget,
    QuestionDurationScriptWidget,
    VueEasyLightbox,
    CopyPasteInstructions,
    EditCleaningSettingsModal
  },
  data() {
    return {
      enableRedemApiScript: "redem_api",
      datapointTypesById: {},
      dataPointSummary: [],
      datapointIdsWithoutTS: [],
      TSDatapointIds: [],
      TSDatapoints: [],
      oesDatapointIDS: [],
      totalSurveyDurationIfBranch: "continue;",
      nipoScriptUrl: process.env.VUE_APP_NIPO_SCRIPT_URL,
      steps: {
        questionDuration: 2,
        copyPastes: 2,
        final: 2
      },
      ALGORITHM_TYPES,
      enableCopyPasteCheck: false,
      /****** Operational Varaibles *****/
      isExpanded: true,
      lightbox1Visible: false,
      lightbox2Visible: false,
      lightbox3Visible: false,
      lightbox4Visible: false,
      displayEditCleaningSettingsModal: false,
      step1Visible: false,
      step2Visible: false,
      step3Visible: false,
      step4Visible: false,
      step5Visible: false,
      step6Visible: false
    };
  },
  created() {
    if (this.trackTotalSurveyDuration) {
      this.datapointTypesById["Survey_Duration"] = "TS";
      this.totalSurveyDurationIfBranch = `var startTime = InterviewStart().getTime();
            var currentTime = new Date();
            var oneHour = 3600000;
            currentTime = currentTime.getTime() + oneHour;
            duration = Math.round(currentTime - startTime);`;
    }
    let questionTimestampsAddedtoSteps = false;
    for (const datapoint of Object.values(this.datapoints)) {
      if (!datapoint.questionQualityScores) continue;
      const qualityScores = datapoint.questionQualityScores?.split("+");
      this.dataPointSummary.push(
        {
          datapointId: datapoint.questionIdentifier,
          qualityScores: qualityScores
        }
      )
      for (const qualityScore of qualityScores) {
        let datapointID = `${datapoint["questionIdentifier"]}`;
        if (qualityScore === ALGORITHM_TYPES.TS) {
          datapointID += "_Duration";
          this.TSDatapointIds.push(datapointID);
          this.TSDatapoints.push({
            datapointID
          });
          if (!questionTimestampsAddedtoSteps) {
            this.steps.copyPastes++;
            this.steps.final++;
            questionTimestampsAddedtoSteps = true;
          }
        } else {
          this.datapointIdsWithoutTS.push({
            datapointID,
            datapointType: qualityScore,
            questionTitle: datapoint.questionTitle
          });
          if (qualityScore === ALGORITHM_TYPES.OES) {
            this.oesDatapointIDS.push(datapointID);
            if (datapoint.isEnableCopyPasteCheck) {
              this.enableCopyPasteCheck = true;
            }
          }
        }
        this.datapointTypesById[datapointID] = qualityScore;
      }
    }
    if (this.enableCopyPasteCheck > 0) {
      // Copy Paste Instructions
      this.steps.final++;
    }
    //check whatever settings saved on navbar on page load
    const navBarSetting = window.localStorage.getItem("navBarSetting");
    if (navBarSetting) this.isExpanded = navBarSetting == "expandNav";

    EventBus.$on("sidebar-toggle-action", async () => {
      this.isExpanded = !this.isExpanded;
    });
  },
  methods: {
    getQuestionDurationScript() {
      return `TimeTracker(CurrentForm())`;
    },
    clickFinishButton() {
      // redirect to datafiles page
      this.$router.push("/datafiles");
    },
    clickEditCleaningSettings() {
      this.displayEditCleaningSettingsModal = true;
    },
    clickBackToResult() {
      this.$router.push({
        path: "datafile-result",
        query: { datafileId: this.datafileId }
      })
    } 
  }
};
</script>

<template>
  <div>
    <!-- Instruction Card -->
    <div class="row">
      <div class="col-md-8">
        <div class="card-title">
          {{ $t("integrationInstructions.forsta.intro.header") }}
        </div>
        <div class="card-text">
          <ul>
            <li>
              {{ $t("integrationInstructions.forsta.intro.description1") }}
            </li>
            <li>
              {{ $t("integrationInstructions.forsta.intro.description2") }}
            </li>
            <li>
              {{ $t("integrationInstructions.forsta.intro.description3") }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 text-right">
        <b-button
          variant="dark"
          class="btn-sm mr-2"
        >
          <a class="text-white" href="https://redem-resources.s3.eu-central-1.amazonaws.com/survey-templates/ReDem+Quality+Check+Template.zip"> <i class='bx bxs-download mr-1'></i> {{$t("integrationInstructions.forsta.intro.downloadTemplateButton")}}</a>
        </b-button>
        <b-button
          variant="primary"
          @click="clickEditCleaningSettings()"
          class="btn-sm"
        >
          <i class="bx bx-brush mr-2"></i>
          {{ $t("integrationInstructions.general.EditCleaningSettingButton") }}
        </b-button>
      </div>
    </div>
    
    <!-- Datapoint IDs -->
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="row">
                <div class="col-8">
                  {{ $t("integrationInstructions.forsta.dataPoints.header") }}
                </div>
                <div class="col-4 text-right">
                  <b-button v-b-toggle.collapse-1 variant="primary" class="pl-2" @click="step1Visible = !step1Visible" size="sm">
                    <div v-if="!step1Visible">
                      <i class='bx bx-chevron-down mr-1'></i> {{ $t("integrationInstructions.forsta.general.showInstructions") }}
                    </div>
                    <div v-else>
                      <i class='bx bx-chevron-up mr-1'></i> {{ $t("integrationInstructions.forsta.general.hideInstructions") }}
                    </div>
                  </b-button>
                </div>
              </div>
            </div>
            <b-collapse id="collapse-1" class="mt-3">
              <div class="card-text mt-3">
                <ul>
                  <li>
                    {{ $t("integrationInstructions.forsta.dataPoints.description1") }}
                  </li>
                  <li>
                    {{ $t("integrationInstructions.forsta.dataPoints.description2") }}
                  </li>
                </ul>
                <div v-if="dataPointSummary.length > 0">
                  <div class="row">
                    <div class="col-md-6">
                      <table class="table mt-3 mb-5">
                        <thead>
                          <tr>
                            <th class="">
                              {{$t("integrationInstructions.forsta.dataPoints.pagesTable.designation")}}
                            </th>
                            <th class="">
                              {{$t("integrationInstructions.forsta.dataPoints.pagesTable.qualityScore")}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(datapoint, datapointIndex) in dataPointSummary" :key="datapointIndex">
                            <td>
                              <label>
                                {{ datapoint.datapointId }}
                              </label>
                            </td>
                            <td class="">
                              <span v-for="(qualityScore, qualityScoreIndex) in datapoint.qualityScores" :key="qualityScoreIndex">
                                <b-badge
                                  style="font-size: 12px"
                                  variant="primary"
                                  class="mr-2"
                                >
                                  {{ qualityScore }}
                                </b-badge>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                
                  <b> {{ $t("integrationInstructions.forsta.dataPoints.example.label") }} </b> 
                  <ul>
                    <li> {{ $t("integrationInstructions.forsta.dataPoints.example.example1") }} </li>
                    <li> {{ $t("integrationInstructions.forsta.dataPoints.example.example2") }} </li>
                    <li> {{ $t("integrationInstructions.forsta.dataPoints.example.example3") }} </li>
                  </ul>

                  <figure>
                    <img
                      class="mb-2 mt-2"
                      src="https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step1_Image1.png"
                      width="30%"
                      @click="lightbox1Visible = true"
                    />
                    <figcaption style="color: #bababa">{{ $t("integrationInstructions.forsta.general.clickToEnlarge") }}</figcaption>
                  </figure>
                  <VueEasyLightbox
                    :visible="lightbox1Visible"
                    imgs="https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step1_Image1.png"
                    @hide="lightbox1Visible = false"
                  />
              
                </div>
                <div v-else>
                  <p><i>{{ $t("integrationInstructions.forsta.dataPoints.noDatapoints") }}</i></p>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>

    <!-- Individual Questions Time Tracking -->
    <div
      v-if="TSDatapointIds.length > 0"
      class="row justify-content-center"
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="row">
                <div class="col-8">
                  {{ $t("integrationInstructions.forsta.stepQuestionDuration.header1") }} {{ steps.questionDuration }}{{ $t("integrationInstructions.forsta.stepQuestionDuration.header2") }}
                </div>
                <div class="col-4 text-right">
                  <b-button v-b-toggle.collapse-2 variant="primary" class="pl-2" @click="step2Visible = !step2Visible" size="sm">
                    <div v-if="!step2Visible">
                      <i class='bx bx-chevron-down mr-1'></i> {{ $t("integrationInstructions.forsta.general.showInstructions") }}
                    </div>
                    <div v-else>
                      <i class='bx bx-chevron-up mr-1'></i> {{ $t("integrationInstructions.forsta.general.hideInstructions") }}
                    </div>
                  </b-button>
                </div>
              </div>
            </div>
            <b-collapse id="collapse-2" class="mt-2">
              <div class="card-text mt-3">
              <p>
                {{ $t("integrationInstructions.forsta.stepQuestionDuration.description1") }}
              </p>
              <figure>
                <img
                  class="mb-2 mt-2"
                  src="https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step2_Image1.png"
                  width="30%"
                  @click="lightbox2Visible = true"
                />
                <figcaption style="color: #bababa">{{ $t("integrationInstructions.forsta.general.clickToEnlarge") }}</figcaption>
              </figure>
              <VueEasyLightbox
                :visible="lightbox2Visible"
                imgs="https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step2_Image1.png"
                @hide="lightbox2Visible = false"
              />      
                      
              <p>
                {{ $t("integrationInstructions.forsta.stepQuestionDuration.description2") }}
              </p>
              
              <ul>
                <li v-for="(datapoint, datapointIndex) in TSDatapoints" :key="datapointIndex">
                  {{datapoint.datapointID.split("_")[0]}}
                </li>
              </ul>
                      
              <QuestionDurationScriptWidget
                :scriptContent="getQuestionDurationScript()"
                scriptHeight="height: 25px"
              />
            </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Copy Pastes -->
    <div class="row justify-content-center" v-if="enableCopyPasteCheck">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="row">
                <div class="col-8">
                  {{ $t("integrationInstructions.forsta.stepCopyPaste.header1") }}{{ steps.copyPastes }}{{ $t("integrationInstructions.forsta.stepCopyPaste.header2") }}
                </div>
                <div class="col-4 text-right">
                  <b-button v-b-toggle.collapse-5 variant="primary" class="pl-2" @click="step5Visible = !step5Visible" size="sm">
                    <div v-if="!step5Visible">
                      <i class='bx bx-chevron-down mr-1'></i> {{ $t("integrationInstructions.forsta.general.showInstructions") }}
                    </div>
                    <div v-else>
                      <i class='bx bx-chevron-up mr-1'></i> {{ $t("integrationInstructions.forsta.general.hideInstructions") }}
                    </div>
                  </b-button>
                </div>
              </div>
            </div>
            <b-collapse id="collapse-5" class="mt-2">
              <CopyPasteInstructions
                :oesDatapointIDS="oesDatapointIDS"
              />   
            </b-collapse>         
          </div>
        </div>
      </div>
    </div> 

    <!-- Main Script Node -->
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="row">
                <div class="col-8">
                  {{ $t("integrationInstructions.forsta.stepFinal.header1") }}{{ steps.final }}{{ $t("integrationInstructions.forsta.stepFinal.header2") }}
                </div>
                <div class="col-4 text-right">
                  <b-button v-b-toggle.collapse-6 variant="primary" class="pl-2" @click="step6Visible = !step6Visible" size="sm">
                    <div v-if="!step6Visible">
                      <i class='bx bx-chevron-down mr-1'></i> {{ $t("integrationInstructions.forsta.general.showInstructions") }}
                    </div>
                    <div v-else>
                      <i class='bx bx-chevron-up mr-1'></i> {{ $t("integrationInstructions.forsta.general.hideInstructions") }}
                    </div>
                  </b-button>
                </div>
              </div>
            </div>

            <b-collapse id="collapse-6" class="mt-2">
              <div class="card-text mt-3">
                <ul>
                  <li>
                    {{ $t("integrationInstructions.forsta.stepFinal.description1") }}
                  </li>
                </ul>

                <figure>
                  <img
                    class="mb-2 mt-2"
                    src="https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step4_Image1.png"
                    width="30%"
                    @click="lightbox4Visible = true"
                  />
                  <figcaption style="color: #bababa">{{ $t("integrationInstructions.forsta.general.clickToEnlarge") }}</figcaption>
                </figure>
                <VueEasyLightbox
                  :visible="lightbox4Visible"
                  imgs="https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step4_Image1.png"
                  @hide="lightbox4Visible = false"
                />      
              
                <ScriptWidget
                  class="mt-2 mb-2"
                  :datapointTypesById="datapointTypesById"
                  :datafileId="datafileId"
                  :datafileSecretKey="datafileSecretKey"
                  :totalSurveyDurationIfBranch="totalSurveyDurationIfBranch"
                  :enableCopyPasteCheck="enableCopyPasteCheck"
                />
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>

    <!--Button Container -->
    <div
      class="fixed-bottom static-buttons"
      v-bind:class="[isExpanded ? 'expanded' : 'collapsed']"
    >
      <div class="card-body col-md-12 row">
        <div class="col-md-12 text-right">
          <a href="https://help.redem.io/docs/data-import/live-import-integrations/forsta-plus-integration/" target="_blank"
            >{{ $t("integrationInstructions.general.bestPracticesButton") }}
            <i class="bx bx-link-external ml-1"></i
          ></a>

          <b-button
            v-if="!returnToResult"
            variant="primary"
            @click="clickFinishButton()"
            class="ml-4 mr-2"
          >
            {{ $t("integrationInstructions.general.finishButton") }}
            <i class="bx bx-check-double"></i>
          </b-button>

          <b-button
            v-if="returnToResult"
            variant="primary"
            @click="clickBackToResult()"
            class="ml-4 mr-2"
          >
          {{ $t("integrationInstructions.general.backButton") }}
            <i class='bx bx-right-arrow-alt'></i>
          </b-button>
        </div>
      </div>
    </div>
    
    <!-- Edit Cleaning Settings Modal -->
    <EditCleaningSettingsModal
      v-if="displayEditCleaningSettingsModal"
      :visible="displayEditCleaningSettingsModal"
      @close="displayEditCleaningSettingsModal = false"
    />     
  </div>
</template>

<style scoped>
.static-buttons {
  z-index: 1;
  margin: 0;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.collapsed {
  padding-left: 75px;
}

.expanded {
  padding-left: 250px;
}

.pointer {
  cursor: pointer;
}
</style>
