<script>
export default {
  props: {
    scriptContent: String,
    scriptHeight: String
  },
  data() {
    return {
      /****** Operational Varaibles *****/
      copySuccess: false,
      copyFail: false
    };
  },
  methods: {
    copyScriptToClipboard(textToCopy) {
      try {
        navigator.clipboard.writeText(textToCopy);
        this.copySuccess = true;
      } catch (err) {
        this.copyFail = true;
        setTimeout(() => {
          this.copyFail = false;
          this.copySuccess = false;
        }, 2000);
        throw err;
      }
    },
  }
};
</script>

<template>
  <div class="input-group bg-light rounded mt-3 mb-3">
    <input
      type="text"
      class="form-control bg-transparent border-0"
      v-model="this.scriptContent"
      disabled
    />

    <button
      class="btn btn-light"
      type="button"
      id="button-addon2"
      @click="copyScriptToClipboard(scriptContent)"
    >
      <span v-if="copySuccess">
        <b-badge
          class="mr-2"
          style="font-size: 12px"
          variant="success"
        >
          {{ $t("integrationInstructions.general.copied") }}
        </b-badge></span
      >
      <i class="bx bx-copy-alt"></i>
    </button>
  </div>
</template>
