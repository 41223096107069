var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-8" }, [
          _c("div", { staticClass: "card-title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("integrationInstructions.forsta.intro.header")) +
                " "
            ),
          ]),
          _c("div", { staticClass: "card-text" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "integrationInstructions.forsta.intro.description1"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "integrationInstructions.forsta.intro.description2"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "integrationInstructions.forsta.intro.description3"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-md-4 text-right" },
          [
            _c(
              "b-button",
              { staticClass: "btn-sm mr-2", attrs: { variant: "dark" } },
              [
                _c(
                  "a",
                  {
                    staticClass: "text-white",
                    attrs: {
                      href: "https://redem-resources.s3.eu-central-1.amazonaws.com/survey-templates/ReDem+Quality+Check+Template.zip",
                    },
                  },
                  [
                    _c("i", { staticClass: "bx bxs-download mr-1" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "integrationInstructions.forsta.intro.downloadTemplateButton"
                          )
                        )
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "b-button",
              {
                staticClass: "btn-sm",
                attrs: { variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.clickEditCleaningSettings()
                  },
                },
              },
              [
                _c("i", { staticClass: "bx bx-brush mr-2" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "integrationInstructions.general.EditCleaningSettingButton"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("div", { staticClass: "card-title" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-8" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "integrationInstructions.forsta.dataPoints.header"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-4 text-right" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.collapse-1",
                                modifiers: { "collapse-1": true },
                              },
                            ],
                            staticClass: "pl-2",
                            attrs: { variant: "primary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.step1Visible = !_vm.step1Visible
                              },
                            },
                          },
                          [
                            !_vm.step1Visible
                              ? _c("div", [
                                  _c("i", {
                                    staticClass: "bx bx-chevron-down mr-1",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "integrationInstructions.forsta.general.showInstructions"
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _c("i", {
                                    staticClass: "bx bx-chevron-up mr-1",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "integrationInstructions.forsta.general.hideInstructions"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "b-collapse",
                  { staticClass: "mt-3", attrs: { id: "collapse-1" } },
                  [
                    _c("div", { staticClass: "card-text mt-3" }, [
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "integrationInstructions.forsta.dataPoints.description1"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "integrationInstructions.forsta.dataPoints.description2"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _vm.dataPointSummary.length > 0
                        ? _c(
                            "div",
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-6" }, [
                                  _c(
                                    "table",
                                    { staticClass: "table mt-3 mb-5" },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th", {}, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "integrationInstructions.forsta.dataPoints.pagesTable.designation"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("th", {}, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "integrationInstructions.forsta.dataPoints.pagesTable.qualityScore"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.dataPointSummary,
                                          function (datapoint, datapointIndex) {
                                            return _c(
                                              "tr",
                                              { key: datapointIndex },
                                              [
                                                _c("td", [
                                                  _c("label", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          datapoint.datapointId
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]),
                                                _c(
                                                  "td",
                                                  {},
                                                  _vm._l(
                                                    datapoint.qualityScores,
                                                    function (
                                                      qualityScore,
                                                      qualityScoreIndex
                                                    ) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          key: qualityScoreIndex,
                                                        },
                                                        [
                                                          _c(
                                                            "b-badge",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    qualityScore
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("b", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "integrationInstructions.forsta.dataPoints.example.label"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "integrationInstructions.forsta.dataPoints.example.example1"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "integrationInstructions.forsta.dataPoints.example.example2"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "integrationInstructions.forsta.dataPoints.example.example3"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("figure", [
                                _c("img", {
                                  staticClass: "mb-2 mt-2",
                                  attrs: {
                                    src: "https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step1_Image1.png",
                                    width: "30%",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.lightbox1Visible = true
                                    },
                                  },
                                }),
                                _c(
                                  "figcaption",
                                  { staticStyle: { color: "#bababa" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "integrationInstructions.forsta.general.clickToEnlarge"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _c("VueEasyLightbox", {
                                attrs: {
                                  visible: _vm.lightbox1Visible,
                                  imgs: "https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step1_Image1.png",
                                },
                                on: {
                                  hide: function ($event) {
                                    _vm.lightbox1Visible = false
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("p", [
                              _c("i", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "integrationInstructions.forsta.dataPoints.noDatapoints"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm.TSDatapointIds.length > 0
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("div", { staticClass: "card-title" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "integrationInstructions.forsta.stepQuestionDuration.header1"
                                )
                              ) +
                              " " +
                              _vm._s(_vm.steps.questionDuration) +
                              _vm._s(
                                _vm.$t(
                                  "integrationInstructions.forsta.stepQuestionDuration.header2"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-4 text-right" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.collapse-2",
                                    modifiers: { "collapse-2": true },
                                  },
                                ],
                                staticClass: "pl-2",
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.step2Visible = !_vm.step2Visible
                                  },
                                },
                              },
                              [
                                !_vm.step2Visible
                                  ? _c("div", [
                                      _c("i", {
                                        staticClass: "bx bx-chevron-down mr-1",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "integrationInstructions.forsta.general.showInstructions"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [
                                      _c("i", {
                                        staticClass: "bx bx-chevron-up mr-1",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "integrationInstructions.forsta.general.hideInstructions"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "b-collapse",
                      { staticClass: "mt-2", attrs: { id: "collapse-2" } },
                      [
                        _c(
                          "div",
                          { staticClass: "card-text mt-3" },
                          [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "integrationInstructions.forsta.stepQuestionDuration.description1"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("figure", [
                              _c("img", {
                                staticClass: "mb-2 mt-2",
                                attrs: {
                                  src: "https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step2_Image1.png",
                                  width: "30%",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.lightbox2Visible = true
                                  },
                                },
                              }),
                              _c(
                                "figcaption",
                                { staticStyle: { color: "#bababa" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "integrationInstructions.forsta.general.clickToEnlarge"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("VueEasyLightbox", {
                              attrs: {
                                visible: _vm.lightbox2Visible,
                                imgs: "https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step2_Image1.png",
                              },
                              on: {
                                hide: function ($event) {
                                  _vm.lightbox2Visible = false
                                },
                              },
                            }),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "integrationInstructions.forsta.stepQuestionDuration.description2"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.TSDatapoints,
                                function (datapoint, datapointIndex) {
                                  return _c("li", { key: datapointIndex }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          datapoint.datapointID.split("_")[0]
                                        ) +
                                        " "
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                            _c("QuestionDurationScriptWidget", {
                              attrs: {
                                scriptContent: _vm.getQuestionDurationScript(),
                                scriptHeight: "height: 25px",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.enableCopyPasteCheck
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("div", { staticClass: "card-title" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "integrationInstructions.forsta.stepCopyPaste.header1"
                                )
                              ) +
                              _vm._s(_vm.steps.copyPastes) +
                              _vm._s(
                                _vm.$t(
                                  "integrationInstructions.forsta.stepCopyPaste.header2"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-4 text-right" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.collapse-5",
                                    modifiers: { "collapse-5": true },
                                  },
                                ],
                                staticClass: "pl-2",
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.step5Visible = !_vm.step5Visible
                                  },
                                },
                              },
                              [
                                !_vm.step5Visible
                                  ? _c("div", [
                                      _c("i", {
                                        staticClass: "bx bx-chevron-down mr-1",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "integrationInstructions.forsta.general.showInstructions"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [
                                      _c("i", {
                                        staticClass: "bx bx-chevron-up mr-1",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "integrationInstructions.forsta.general.hideInstructions"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "b-collapse",
                      { staticClass: "mt-2", attrs: { id: "collapse-5" } },
                      [
                        _c("CopyPasteInstructions", {
                          attrs: { oesDatapointIDS: _vm.oesDatapointIDS },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("div", { staticClass: "card-title" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-8" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "integrationInstructions.forsta.stepFinal.header1"
                            )
                          ) +
                          _vm._s(_vm.steps.final) +
                          _vm._s(
                            _vm.$t(
                              "integrationInstructions.forsta.stepFinal.header2"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-4 text-right" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.collapse-6",
                                modifiers: { "collapse-6": true },
                              },
                            ],
                            staticClass: "pl-2",
                            attrs: { variant: "primary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.step6Visible = !_vm.step6Visible
                              },
                            },
                          },
                          [
                            !_vm.step6Visible
                              ? _c("div", [
                                  _c("i", {
                                    staticClass: "bx bx-chevron-down mr-1",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "integrationInstructions.forsta.general.showInstructions"
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _c("i", {
                                    staticClass: "bx bx-chevron-up mr-1",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "integrationInstructions.forsta.general.hideInstructions"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "b-collapse",
                  { staticClass: "mt-2", attrs: { id: "collapse-6" } },
                  [
                    _c(
                      "div",
                      { staticClass: "card-text mt-3" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "integrationInstructions.forsta.stepFinal.description1"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("figure", [
                          _c("img", {
                            staticClass: "mb-2 mt-2",
                            attrs: {
                              src: "https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step4_Image1.png",
                              width: "30%",
                            },
                            on: {
                              click: function ($event) {
                                _vm.lightbox4Visible = true
                              },
                            },
                          }),
                          _c(
                            "figcaption",
                            { staticStyle: { color: "#bababa" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "integrationInstructions.forsta.general.clickToEnlarge"
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                        _c("VueEasyLightbox", {
                          attrs: {
                            visible: _vm.lightbox4Visible,
                            imgs: "https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step4_Image1.png",
                          },
                          on: {
                            hide: function ($event) {
                              _vm.lightbox4Visible = false
                            },
                          },
                        }),
                        _c("ScriptWidget", {
                          staticClass: "mt-2 mb-2",
                          attrs: {
                            datapointTypesById: _vm.datapointTypesById,
                            datafileId: _vm.datafileId,
                            datafileSecretKey: _vm.datafileSecretKey,
                            totalSurveyDurationIfBranch:
                              _vm.totalSurveyDurationIfBranch,
                            enableCopyPasteCheck: _vm.enableCopyPasteCheck,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "fixed-bottom static-buttons",
          class: [_vm.isExpanded ? "expanded" : "collapsed"],
        },
        [
          _c("div", { staticClass: "card-body col-md-12 row" }, [
            _c(
              "div",
              { staticClass: "col-md-12 text-right" },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://help.redem.io/docs/data-import/live-import-integrations/forsta-plus-integration/",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "integrationInstructions.general.bestPracticesButton"
                        )
                      ) + " "
                    ),
                    _c("i", { staticClass: "bx bx-link-external ml-1" }),
                  ]
                ),
                !_vm.returnToResult
                  ? _c(
                      "b-button",
                      {
                        staticClass: "ml-4 mr-2",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.clickFinishButton()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "integrationInstructions.general.finishButton"
                              )
                            ) +
                            " "
                        ),
                        _c("i", { staticClass: "bx bx-check-double" }),
                      ]
                    )
                  : _vm._e(),
                _vm.returnToResult
                  ? _c(
                      "b-button",
                      {
                        staticClass: "ml-4 mr-2",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.clickBackToResult()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "integrationInstructions.general.backButton"
                              )
                            ) +
                            " "
                        ),
                        _c("i", { staticClass: "bx bx-right-arrow-alt" }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.displayEditCleaningSettingsModal
        ? _c("EditCleaningSettingsModal", {
            attrs: { visible: _vm.displayEditCleaningSettingsModal },
            on: {
              close: function ($event) {
                _vm.displayEditCleaningSettingsModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }