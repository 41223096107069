var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-group bg-light rounded mt-3 mb-3" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: this.scriptContent,
          expression: "this.scriptContent",
        },
      ],
      staticClass: "form-control bg-transparent border-0",
      attrs: { type: "text", disabled: "" },
      domProps: { value: this.scriptContent },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(this, "scriptContent", $event.target.value)
        },
      },
    }),
    _c(
      "button",
      {
        staticClass: "btn btn-light",
        attrs: { type: "button", id: "button-addon2" },
        on: {
          click: function ($event) {
            return _vm.copyScriptToClipboard(_vm.scriptContent)
          },
        },
      },
      [
        _vm.copySuccess
          ? _c(
              "span",
              [
                _c(
                  "b-badge",
                  {
                    staticClass: "mr-2",
                    staticStyle: { "font-size": "12px" },
                    attrs: { variant: "success" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("integrationInstructions.general.copied")
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("i", { staticClass: "bx bx-copy-alt" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }