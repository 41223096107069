var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: {
          title: this.$t("integrationInstructions.header"),
          items: _vm.computedItems,
        },
      }),
      _vm.isLoading
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isLoading && !_vm.datafile
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center p-5" },
              [_c("DataFetchingErrorWidget")],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isLoading && _vm.datafile
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _vm.datafile.integration.name === "keyingress"
                    ? _c("IngressInstructions", {
                        attrs: {
                          datafileSecretKey: _vm.datafile.datafileSecretKey,
                          datapoints: _vm.datapointsParameters,
                          returnToResult: _vm.returnToResult,
                          datafileId: _vm.datafileId,
                        },
                      })
                    : _vm._e(),
                  _vm.datafile.integration.name === "NIPO"
                    ? _c("NIPOInstructions", {
                        attrs: {
                          datafileSecretKey: _vm.datafile.datafileSecretKey,
                          datapoints: _vm.datapointsParameters,
                          returnToResult: _vm.returnToResult,
                          datafileId: _vm.datafileId,
                        },
                      })
                    : _vm._e(),
                  _vm.datafile.integration.name === "Forsta Plus"
                    ? _c("ForstaInstructions", {
                        attrs: {
                          datafileSecretKey: _vm.datafile.datafileSecretKey,
                          datafileId: _vm.datafileId,
                          datapoints: _vm.datapointsParameters,
                          trackTotalSurveyDuration:
                            _vm.trackTotalSurveyDuration,
                          returnToResult: _vm.returnToResult,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }