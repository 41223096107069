<script>
import VueEasyLightbox from "vue-easy-lightbox";
import CopyPasteScript from "./forsta-copy-paste-script-widget.vue";

export default {
  props: {
    oesDatapointIDS: Array
  },
  components: {
    VueEasyLightbox,
    CopyPasteScript
  },
  data() {
    return {
      /****** Operational Varaibles *****/
      copySuccess: false,
      copyFail: false,
      lightbox1Visible: false,
      lightbox2Visible: false,
      lightbox3Visible: false
    };
  },
  methods: {
    copyScriptToClipboard(textToCopy) {
      try {
        navigator.clipboard.writeText(textToCopy);
        this.copySuccess = true;
        setTimeout(() => {
          this.copySuccess = false;
        }, 2000);
      } catch (err) {
        this.copyFail = true;
        setTimeout(() => {
          this.copyFail = false;
          this.copySuccess = false;
        }, 2000);
        throw err;
      }
    },
  }
};
</script>

<template>
  <div class="card-text mt-3">
    <!--  Scripts --> 
    <div class="mb-5">
      <ul>
        <li> {{ $t("integrationInstructions.forsta.stepCopyPaste.description1") }} </li>
      </ul>
      <figure>
        <img
          class="mb-2 mt-2"
          src="https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step3_Image1.png"
          width="30%"
          @click="lightbox2Visible = true"
        />
        <figcaption style="color: #bababa">{{ $t("integrationInstructions.forsta.general.clickToEnlarge") }}</figcaption>
      </figure>
      <VueEasyLightbox
        :visible="lightbox2Visible"
        imgs="https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step3_Image1.png"
        @hide="lightbox2Visible = false"
      />

      <ul>
        <li> {{ $t("integrationInstructions.forsta.stepCopyPaste.description2") }} </li>
      </ul>

      <ol class="mt-3">
        <li
          class="mb-3"
          v-for="(datapointID, datapointIndex) in oesDatapointIDS"
          :key="datapointIndex"
        >
          <p><b>{{ datapointID }}</b><br class="mb-2"/>
          </p>
          <CopyPasteScript
            class="mb-2"
            :datapointID="datapointID"
            :isScriptNode="false"
            scriptHeight="height: 151px;"
          />
        </li>  
      </ol> 
    </div>

    <hr/>
        
    <!--  Script Nodes -->
    <div class="mt-5">
      <ul>
        <li> {{ $t("integrationInstructions.forsta.stepCopyPaste.description3") }}</li>
        <li> {{ $t("integrationInstructions.forsta.stepCopyPaste.description4") }}</li>
      </ul>
      <figure>
        <img
          class="mb-2 mt-2"
          src="https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step3_Image2.png"
          width="30%"
          @click="lightbox3Visible = true"
        />
        <figcaption style="color: #bababa">{{ $t("integrationInstructions.forsta.general.clickToEnlarge") }}</figcaption>
      </figure>
      <VueEasyLightbox
        :visible="lightbox3Visible"
        imgs="https://redem-resources.s3.eu-central-1.amazonaws.com/instruction-screenshots/Forsta_Step3_Image2.png"
        @hide="lightbox3Visible = false"
      />

      <p>{{ $t("integrationInstructions.forsta.stepCopyPaste.description5") }}</p>

      <ol>
        <li
          v-for="(datapointID, datapointIndex) in oesDatapointIDS"
          :key="datapointIndex"
          class="mb-3"
        >
          <p><b>{{ datapointID }}_CopyPasteDetection</b><br class="mb-2"/></p>
          <CopyPasteScript
            class="mb-2"
            :datapointID="datapointID"
            :isScriptNode="true"
            scriptHeight="height: 249px;"
          />
        </li>  
      </ol>
    </div>
              
  </div>
</template>
