<script>
export default {
  props: {
    datapointID: String,
    isScriptNode: Boolean,
    scriptHeight: String
  },
  mounted() {
    if (this.isScriptNode) {
      this.copyPasteJavaScript = `var redemVariables = f("redemVariables");
if (redemVariables.toBoolean()) {
    redemVariables = JSON.parse(redemVariables.get());
} else {
    redemVariables = {};
}
if (Object.keys(redemVariables).includes("copyPastes")) {
    redemVariables["copyPastes"]["${this.datapointID}"] = String(Request.Form.item("copyPasteFlag"));
} else {
   redemVariables["copyPastes"] = {
    "${this.datapointID}": String(Request.Form.item("copyPasteFlag"))
   }
}
f("redemVariables").set(JSON.stringify(redemVariables));`
    }
  },
  data() {
    return {
      copyPasteJavaScript: `var inputElement = document.querySelector('#${this.datapointID}_input.cf-open-answer__input.cf-text-area');
inputElement.addEventListener('paste', function (event) {
    var pastedText = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (pastedText.length > 0) {
        Confirmit.page.serverVariables.add("copyPasteFlag", "true");
    }
});`,
      /****** Operational Varaibles *****/
      copySuccess: false,
      copyFail: false,
      lightbox1Visible: false,
      lightbox2Visible: false,
      lightbox3Visible: false
    };
  },
  methods: {
    copyScriptToClipboard(textToCopy) {
      try {
        navigator.clipboard.writeText(textToCopy);
        this.copySuccess = true;
      } catch (err) {
        this.copyFail = true;
        setTimeout(() => {
          this.copyFail = false;
          this.copySuccess = false;
        }, 2000);
        throw err;
      }
    },
  }
};
</script>

<template>
  <div class="input-group bg-light rounded mt-3">
    <textarea
      type="text"
      class="form-control bg-transparent border-0"
      v-model="this.copyPasteJavaScript"
      disabled
      :style="this.scriptHeight"
    />

    <button
      class="btn btn-light"
      type="button"
      id="button-addon2"
      @click="copyScriptToClipboard(copyPasteJavaScript)"
    >
      <span v-if="this.copySuccess">
        <b-badge
          class="mr-2"
          style="font-size: 12px"
          variant="success"
        >
          {{ $t("integrationInstructions.general.copied") }}
        </b-badge></span
      >
      <i class="bx bx-copy-alt"></i>
    </button>
  </div>
</template>
