var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-8" }, [
          _c("div", { staticClass: "card-title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("integrationInstructions.ingress.step1.header")) +
                " "
            ),
          ]),
          _c("div", { staticClass: "card-text" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("integrationInstructions.ingress.step1.text1")
                    ) +
                    " "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("integrationInstructions.ingress.step1.text2")
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-md-4 text-right" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-sm",
                attrs: { variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.clickEditCleaningSettings()
                  },
                },
              },
              [
                _c("i", { staticClass: "bx bx-brush mr-2" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "integrationInstructions.general.EditCleaningSettingButton"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _vm._l(_vm.datapoints, function (datapoint, datapointIndex) {
              return _c("div", { key: datapointIndex, staticClass: "mt-3" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("label", [_vm._v(" " + _vm._s(datapoint.pageTitle))]),
                      datapoint.description
                        ? _c("p", [
                            _vm._v(" " + _vm._s(datapoint.description) + " "),
                          ])
                        : _vm._e(),
                      datapoint.pageTitle === "Start Page"
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "integrationInstructions.ingress.start.description"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("ScriptWidget", {
                        attrs: { datapointsParameters: datapoint },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("label", [_vm._v(" ReDem® End Page")]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "integrationInstructions.ingress.end.description"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "input-group bg-light rounded" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: this.endScript,
                        expression: "this.endScript",
                      },
                    ],
                    staticClass: "form-control bg-transparent border-0",
                    staticStyle: { height: "120px" },
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: this.endScript },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(this, "endScript", $event.target.value)
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light",
                      attrs: { type: "button", id: "button-addon2" },
                      on: {
                        click: function ($event) {
                          return _vm.copyEndScriptToClipboard()
                        },
                      },
                    },
                    [
                      this.copyEndScriptSuccess
                        ? _c(
                            "span",
                            [
                              _c(
                                "b-badge",
                                {
                                  staticClass: "mr-2",
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { variant: "success" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "integrationInstructions.general.copied"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("i", { staticClass: "bx bx-copy-alt" }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "fixed-bottom static-buttons",
          class: [_vm.isExpanded ? "expanded" : "collapsed"],
        },
        [
          _c("div", { staticClass: "card-body col-md-12 row" }, [
            _c(
              "div",
              { staticClass: "col-md-12 text-right" },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://help.redem.io/docs/data-import/live-import-integrations/keyingress-integration/",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "integrationInstructions.general.bestPracticesButton"
                        )
                      ) + " "
                    ),
                    _c("i", { staticClass: "bx bx-link-external ml-1" }),
                  ]
                ),
                !_vm.returnToResult
                  ? _c(
                      "b-button",
                      {
                        staticClass: "ml-4 mr-2",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.clickFinishButton()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "integrationInstructions.general.finishButton"
                              )
                            ) +
                            " "
                        ),
                        _c("i", { staticClass: "bx bx-check-double" }),
                      ]
                    )
                  : _vm._e(),
                _vm.returnToResult
                  ? _c(
                      "b-button",
                      {
                        staticClass: "ml-4 mr-2",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.clickBackToResult()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "integrationInstructions.general.backButton"
                              )
                            ) +
                            " "
                        ),
                        _c("i", { staticClass: "bx bx-right-arrow-alt" }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.displayEditCleaningSettingsModal
        ? _c("EditCleaningSettingsModal", {
            attrs: { visible: _vm.displayEditCleaningSettingsModal },
            on: {
              close: function ($event) {
                _vm.displayEditCleaningSettingsModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }