<script>
export default {
  props: {
    datafileId: String,
    datafileSecretKey: String,
    datapointTypesById: Object,
    totalSurveyDurationIfBranch: String,
    enableCopyPasteCheck: Boolean
  },
  data() {
    return {
      liveApiURL: process.env.VUE_APP_LIVE_REDEM_API_URL,
      datapointTypesByIdString: JSON.stringify((this.datapointTypesById || {})),
      /****** Operational Varaibles *****/
      copySuccess: false,
      copyFail: false
    };
  },
  computed: {
    mainScriptNodeContent: {
      get() {
        let variantWithoutCopyPastes = `var DATAFILE_SECRET_KEY = "${this.datafileSecretKey}";
var LIVE_API = "${this.liveApiURL}";
var DATAPOINT_TYPES = {
    "OES": "OES",
    "IBS": "IBS",
    "TS": "TS"
};
var DATAPOINT_TYPES_BY_ID = ${this.datapointTypesByIdString};
var respondentID = String(CurrentID());
var oesAnswersByDatapointID = {};
var itemsByDatapointID = {};
var timestampsByDatapointID = {};
var datapoints = [];
var redemVariables = f("redemVariables");
if (redemVariables.toBoolean()) {
    redemVariables = JSON.parse(redemVariables.get());
} else {
    redemVariables = {};
}
var durations = f("durations");
if (durations.toBoolean()) {
    durations = JSON.parse(durations.get());
} else {
    durations = {};
}
// question id has to be same in Forsta Plus and Redem
for (const datapointID of Object.keys(DATAPOINT_TYPES_BY_ID)) {
    var datapointType = DATAPOINT_TYPES_BY_ID[datapointID];
    if (datapointType === DATAPOINT_TYPES.OES) {
        var answer = f(datapointID).get();
        if (answer || String(answer) === "0") {
            var textAnswer = String(answer);
            datapoints.push({
                "dataPointIdentifier": datapointID,
                "openEndedAnswer": textAnswer
            });
        }
    } else if (datapointType === DATAPOINT_TYPES.IBS) {
        var dP = f(datapointID);
        var items = Object.values(dP.values());
        var numericItems = [];
        for (const item of items) {
            if (!Number.isNaN(Number(item)) && item != "0" && item != null && item != undefined) {
                var num = Number(item);
                numericItems.push(num);
            }
        }
        if (items.length > 0) {
            datapoints.push({
                "dataPointIdentifier": datapointID,
                "itemBattery": numericItems
            });
        }
    } else if (datapointType === DATAPOINT_TYPES.TS) {
        var duration;
        if (datapointID === "Survey_Duration") {
            ${this.totalSurveyDurationIfBranch}
        } else {
            duration = durations[datapointID];
        }
        datapoints.push({
            "dataPointIdentifier": datapointID,
            "timeStamp": Number(duration)
        });
    }
}

var apiBody = {
    "respondentID": respondentID,
    "datafile_secret_key": DATAFILE_SECRET_KEY,
    "datapoints": datapoints
};

var apiHeaders = {
    "Content-Type": "application/json",
    "accept": "application/json"
};

apiBody = JSON.stringify(apiBody);
var createRespondentEndpoint = \`\${LIVE_API}/live-respondent/create\`;
var response = Http.Post(createRespondentEndpoint, apiBody, apiHeaders);
var isIncluded = JSON.parse(response).body.isRespondentInclude;
if (isIncluded) {
    f('ReDemQualityStatus').set('1');
} else {
    f('ReDemQualityStatus').set('2');
    SetStatus("screened");
    Redirect("https://survey.euro.confirmit.com/endofsurvey/");
}`;
        let variantWithCopyPastes = `var DATAFILE_SECRET_KEY = "${this.datafileSecretKey}";
var LIVE_API = "${this.liveApiURL}";
var DATAPOINT_TYPES = {
    "OES": "OES",
    "IBS": "IBS",
    "TS": "TS"
};
var DATAPOINT_TYPES_BY_ID = ${this.datapointTypesByIdString};
var respondentID = String(CurrentID());
var oesAnswersByDatapointID = {};
var itemsByDatapointID = {};
var timestampsByDatapointID = {};
var datapoints = [];
var redemVariables = f("redemVariables");
if (redemVariables.toBoolean()) {
    redemVariables = JSON.parse(redemVariables.get());
} else {
    redemVariables = {};
}
var durations = f("durations");
if (durations.toBoolean()) {
    durations = JSON.parse(durations.get());
} else {
    durations = {};
}
// question id has to be same in Forsta and Redem
for (const datapointID of Object.keys(DATAPOINT_TYPES_BY_ID)) {
    var datapointType = DATAPOINT_TYPES_BY_ID[datapointID];
    if (datapointType === DATAPOINT_TYPES.OES) {
        var answer = f(datapointID).get();
        if (answer || String(answer) === "0") {
            var textAnswer = String(answer);
            datapoints.push({
                "dataPointIdentifier": datapointID,
                "openEndedAnswer": textAnswer,
                "isCopyPaste": redemVariables["copyPastes"][datapointID]
            });
        }
    } else if (datapointType === DATAPOINT_TYPES.IBS) {
        var dP = f(datapointID);
        var items = Object.values(dP.values());
        var numericItems = [];
        for (const item of items) {
            if (!Number.isNaN(Number(item)) && item != "0" && item != null && item != undefined) {
                var num = Number(item);
                numericItems.push(num);
            }
        }
        if (items.length > 0) {
            datapoints.push({
                "dataPointIdentifier": datapointID,
                "itemBattery": numericItems
            });
        }
    } else if (datapointType === DATAPOINT_TYPES.TS) {
        var duration;
        if (datapointID === "Survey_Duration") {
            ${this.totalSurveyDurationIfBranch}
        } else {
            duration = durations[datapointID];
        }
        datapoints.push({
            "dataPointIdentifier": datapointID,
            "timeStamp": Number(duration)
        });
    }
}

var apiBody = {
    "respondentID": respondentID,
    "datafile_secret_key": DATAFILE_SECRET_KEY,
    "datapoints": datapoints
};

var apiHeaders = {
    "Content-Type": "application/json",
    "accept": "application/json"
};

apiBody = JSON.stringify(apiBody);
var createRespondentEndpoint = \`\${LIVE_API}/live-respondent/create\`;
var response = Http.Post(createRespondentEndpoint, apiBody, apiHeaders);
var isIncluded = JSON.parse(response).body.isRespondentInclude;
if (isIncluded) {
    f('ReDemQualityStatus').set('1');
} else {
    f('ReDemQualityStatus').set('2');
    SetStatus("screened");
    Redirect("https://survey.euro.confirmit.com/endofsurvey/");
}`;
        if (this.enableCopyPasteCheck) {
          return variantWithCopyPastes;
        }
        return variantWithoutCopyPastes;
      }
    }
  },
  methods: {
    copyScriptToClipboard() {
      try {
        navigator.clipboard.writeText(this.mainScriptNodeContent);
        this.copySuccess = true;
      } catch (err) {
        this.copyFail = true;
        let self = this;
        setTimeout(() => {
          self.copyFail = false;
        }, 2000);
        throw err;
      }
    }
  }
};
</script>

<template>
  <!-- Main Script Node -->
  <div class="input-group bg-light rounded mt-3">
    <textarea
      type="text"
      class="form-control bg-transparent border-0"
      v-model="this.mainScriptNodeContent"
      disabled
      style="height: 500px"
    />

    <button
      class="btn btn-light"
      type="button"
      id="button-addon2"
      @click="copyScriptToClipboard()"
    >
      <span v-if="this.copySuccess">
        <b-badge
          class="mr-2"
          style="font-size: 12px"
          variant="success"
        >
          {{ $t("integrationInstructions.general.copied") }}
        </b-badge></span
      >
      <i class="bx bx-copy-alt"></i>
    </button>
  </div>
</template>
